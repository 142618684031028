import css from './sass/style.scss';

require('./js/anims.js');
require('./js/carousels.js');
require('./js/glightbox.js');
require('./js/polyfills.js');
require('./js/init.js');
require('./js/map.js');
require('./js/validate.js');

require('./img/about/header.jpg');
require('./img/about/header@2x.jpg');
require('./img/about/thumb-01.jpg');
require('./img/about/thumb-01@2x.jpg');
require('./img/about/thumb-02.jpg');
require('./img/about/thumb-02@2x.jpg')

require('./img/career/header.jpg');
require('./img/career/header@2x.jpg');
require('./img/career/thumb-01.jpg');
require('./img/career/thumb-01@2x.jpg');

require('./img/contact/header.jpg');
require('./img/contact/header@2x.jpg');

require('./img/home/thumb-01.jpg');
require('./img/home/thumb-01@2x.jpg');

require('./img/home/realization-01.jpg');
require('./img/home/realization-01@2x.jpg');
require('./img/home/realization-02.jpg');
require('./img/home/realization-02@2x.jpg');
require('./img/home/realization-03.jpg');
require('./img/home/realization-03@2x.jpg');

require('./img/icons/marker.svg');

require('./img/offer/header.jpg');
require('./img/offer/header@2x.jpg');

require('./img/realization/gallery-01.jpg');
require('./img/realization/gallery-01@2x.jpg');
require('./img/realization/gallery-02.jpg');
require('./img/realization/gallery-02@2x.jpg');
require('./img/realization/gallery-03.jpg');
require('./img/realization/gallery-03@2x.jpg');
require('./img/realization/gallery-04.jpg');
require('./img/realization/gallery-04@2x.jpg');
require('./img/realization/gallery-05.jpg');
require('./img/realization/gallery-05@2x.jpg');
require('./img/realization/gallery-06.jpg');
require('./img/realization/gallery-06@2x.jpg');

require('./img/realizations/header.jpg');
require('./img/realizations/header@2x.jpg');
require('./img/realizations/realization-01.jpg');
require('./img/realizations/realization-01@2x.jpg');
require('./img/realizations/realization-02.jpg');
require('./img/realizations/realization-02@2x.jpg');
require('./img/realizations/realization-03.jpg');
require('./img/realizations/realization-03@2x.jpg');
require('./img/realizations/realization-04.jpg');
require('./img/realizations/realization-04@2x.jpg');
require('./img/realizations/realization-05.jpg');
require('./img/realizations/realization-05@2x.jpg');
require('./img/realizations/realization-06.jpg');
require('./img/realizations/realization-06@2x.jpg');
require('./img/realizations/realization-07.jpg');
require('./img/realizations/realization-07@2x.jpg');
require('./img/realizations/realization-08.jpg');
require('./img/realizations/realization-08@2x.jpg');
require('./img/realizations/realization-09.jpg');
require('./img/realizations/realization-09@2x.jpg');
require('./img/realizations/realization-10.jpg');
require('./img/realizations/realization-10@2x.jpg');

require('./img/realization/header.jpg');
require('./img/realization/header@2x.jpg');

/*
require('./js/helpers.js');
require('./js/ismobile.js');
require('./js/inview.js');
require('./js/fixel.js');
require('./js/scrollpos.js');
require('./js/scrollprogress.js');
require('./js/carousels.js');
require('./js/mobile-detach.js');
require('./js/nav.js');

*/


