document.addEventListener('DOMContentLoaded',function() {

    const cover = document.getElementById('cover');
    
    const init = function() {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        document.getElementsByClassName('js-consultancySlider')[0] ? window.consultancy() : false;
        window.anims();
        
        setTimeout(function() {
            cover.remove();
        }, 250);
    };
    
    window.addEventListener('load', init);

}, false);