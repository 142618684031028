import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);


document.addEventListener('DOMContentLoaded',function() {
    
    const consultancyEl = document.getElementsByClassName('js-consultancySlider')[0];

    window.consultancy = function() {
        
	    const swiper = new Swiper(consultancyEl, {
    	    freeMode: true,
            loop: false,
            spaceBetween: 31.5,
            slidesPerView: 3,
            navigation: {
                nextEl: '.c-consultancy .swiper-button-next',
                prevEl: '.c-consultancy .swiper-button-prev',
            },
        });
    };
    

}, false)
