import { gsap, power2 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {

    gsap.registerPlugin(ScrollTrigger);

    window.anims = function() {
        
        const container = document.getElementsByClassName('js-hero')[0];
        
        const hero = function() {
            
            const blocks = container.querySelectorAll(".js-item");
            const cols = container.querySelectorAll(".js-cols");
            const topbar = document.getElementsByClassName('js-top')[0];
            const figure = container.querySelectorAll('figure');

            const tl = gsap.timeline({

                scrollTrigger: {
                    trigger: '.js-hero',
                    end: '+=80%',
                    scrub: 1,
                    toggleActions: "play reverse play reverse",
                    pin: true,
                    defaults: { duration: 2.1 },
                }
            })
            
            .to(blocks, {
                y: 0,
                
            })
            
            .to(figure, {
                opacity: 1
            }, '<')
            
            .from(cols, {
                scale: 2,
                yPercent: '50'
            }, '<')
        };
        
        container ? hero() : false;
        

        gsap.utils.toArray(".js-fadeIn").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    end: 'center 70%',
                    scrub: true,
                    toggleActions: "play reverse play reverse"
                },
                opacity: 0, 
                duration: 1,
                y: 50,
                
            });
        });  
        
        gsap.utils.toArray(".js-zoomIn").forEach(function(elem) {
            
            const blocks = elem.querySelectorAll(".js-item"),
                  tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: elem,
                        end: "center 40%",
                        scrub: 1.2,
                        toggleActions: "play reverse play reverse"
                        },
                    })
                    .from(blocks, {
                        opacity: 0,
                        scale: .5,
                        stagger: { 
                            each: 0.1,
                          }
                    });
        });
        
        gsap.utils.toArray(".js-moveIn").forEach(function(elem) {
            
            const first = elem.querySelectorAll(".o-cols__item:first-of-type"),
                  last = elem.querySelectorAll(".o-cols__item:last-of-type");
            
            const tl_first = gsap.timeline({
                scrollTrigger: {
                    trigger: elem,
                    end: "top 40%",
                    scrub: 1,
                    },
                })
                .from(first, {
                    opacity: 0,
                    x: 100
                });
                
            const tl_last = gsap.timeline({
                scrollTrigger: {
                    trigger: elem,
                    end: "top 40%",
                    scrub: 1,
                    },
                })
                .from(last, {
                    opacity: 0,
                    x: -100
                });
        });
        
        gsap.utils.toArray(".js-fromTop").forEach(function(elem) {
            
            const el = elem.querySelectorAll(".js-fromTop");
            
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: elem,
                    end: "center",
                    scrub: 1,
                    },
                })
                .from(elem.querySelectorAll('.o-cols__item'), {
                    y: 100,
                    opacity: 0,
                    stagger: {
                        from: "left",
                        each: 0.1
                    }
                });
            });
        
        gsap.utils.toArray(".js-jumpIn").forEach(function(elem) {
            
            const blocks = elem.querySelectorAll(".js-item"),
                  tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: elem,
                        start: "-50px bottom",
                        end: "center center",
                        scrub: true,
                        toggleActions: "play reverse play reverse"
                        },
                    })
                    
                    .from(blocks, {
                        y: 50,
                        stagger: { // wrap advanced options in an object
                            each: 0.2,
                          }
                    });
        });
        
        
/*
        gsap.from('.js-reviewsSlider', {
            scrollTrigger: {
                trigger: '.js-reviewsSlider',
                scrub: 2.2,
                start: 'top bottom',
                end: 'top center'
            },      
            xPercent: 5
        });
        
        gsap.from('.js-knowledgeSlider', {
            scrollTrigger: {
                trigger: '.js-knowledgeSlider',
                scrub: 2.2,
                start: 'top bottom',
                end: 'top center'
            },      
            xPercent: 5
        });
*/
        
        
        
    }

    



}, false)
